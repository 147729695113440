<template>
  <span class="flex items-center">
    {{ getOfficineById ? getOfficineById.NomOffic : '' }}
  </span>
</template>

<script>
export default {
  name: 'CellRendererOfficine',
  props: ['officineId'],
  computed: {
    /* url () {
      return '/apps/user/user-view/268'

    }, */
    officines () {
      return this.$store.state.officine.officines
    },
    getOfficineById () {
      return this.$store.getters['officine/getOfficineById'](this.officineId)
    }
  },
  methods: {
    getOfficines () {
      this.$store.dispatch('officine/getOfficines')
        .then(() => {
        })
        .catch((error) => {
          console.log(error)
        })
    }
  },
  created () {
    this.getOfficines()
  }
}
</script>
